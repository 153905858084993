/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { rhythm } from '../utils/typography'

function Bio() {
  const { t } = useTranslation('bio')

  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author } = data.site.siteMetadata
        return (
          <Container>
            <StaticImage
              src="../images/png/3Dmeet-logo-single.png"
              alt={author}
              layout="fixed"
              width={80}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
              }}
            />
            <p>{t('paragraph1')}</p>
          </Container>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        author
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
