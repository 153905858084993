import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Bio from 'components/bio'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { rhythm, scale } from 'utils/typography'

const LegalPostTemplate = ({ data, location, pageContext }) => {
  const { t } = useTranslation('legalPost')

  const post = data.mdx
  const legalTitle = data.site.siteMetadata.legalTitle
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={legalTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <h1>{post.frontmatter.title}</h1>
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-1),
        }}
      >
        {t('lastUpdated', { date: post.frontmatter.date })}
      </p>
      <MDXRenderer>{post.body}</MDXRenderer>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Bio />

      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={`/legal${previous.fields.slug}`} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={`/legal${next.fields.slug}`} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </Layout>
  )
}

export default LegalPostTemplate

export const pageQuery = graphql`
  query LegalPostBySlug($slug: String!) {
    site {
      siteMetadata {
        legalTitle
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
